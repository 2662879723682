@import '@crystal-eyes/constants.scss';

$tooltip-background-color: #fff;

@mixin crystal-eyes-styles {
  &.fill-disc.empty,
  &.empty &.fill-disc {
    fill: white;
  }
  &.fill-disc.noDisc {
    fill: $gray-text;
  }
  &.fill-disc.Di,
  &.Di &.Parented.fill-disc {
    fill: $disc-Di;
  }
  &.fill-disc.DI,
  &.DI &.Parented.fill-disc {
    fill: $disc-DI;
  }
  &.fill-disc.Id,
  &.Id &.Parented.fill-disc {
    fill: $disc-Id;
  }
  &.fill-disc.I,
  &.I &.Parented.fill-disc {
    fill: $disc-I;
  }
  &.fill-disc.Is,
  &.Is &.Parented.fill-disc {
    fill: $disc-Is;
  }
  &.fill-disc.IS,
  &.IS &.Parented.fill-disc {
    fill: $disc-IS;
  }
  &.fill-disc.Si,
  &.Si &.Parented.fill-disc {
    fill: $disc-Si;
  }
  &.fill-disc.S,
  &.S &.Parented.fill-disc {
    fill: $disc-S;
  }
  &.fill-disc.Sc,
  &.Sc &.Parented.fill-disc {
    fill: $disc-Sc;
  }
  &.fill-disc.SC,
  &.SC &.Parented.fill-disc {
    fill: $disc-SC;
  }
  &.fill-disc.Cs,
  &.Cs &.Parented.fill-disc {
    fill: $disc-Cs;
  }
  &.fill-disc.C,
  &.C &.Parented.fill-disc {
    fill: $disc-C;
  }
  &.fill-disc.Cd,
  &.Cd &.Parented.fill-disc {
    fill: $disc-Cd;
  }
  &.fill-disc.CD,
  &.CD &.Parented.fill-disc {
    fill: $disc-CD;
  }
  &.fill-disc.Dc,
  &.Dc &.Parented.fill-disc {
    fill: $disc-Dc;
  }
  &.fill-disc.D,
  &.D &.Parented.fill-disc {
    fill: $disc-D;
  }

  &.fill-disc-light.empty,
  &.empty &.Parented.fill-disc-light {
    fill: white;
  }
  &.fill-disc-light.Di,
  &.Di &.Parented.fill-disc-light {
    fill: $disc-Di-very-light;
  }
  &.fill-disc-light.DI,
  &.DI &.Parented.fill-disc-light {
    fill: $disc-DI-very-light;
  }
  &.fill-disc-light.Id,
  &.Id &.Parented.fill-disc-light {
    fill: $disc-Id-very-light;
  }
  &.fill-disc-light.I,
  &.I &.Parented.fill-disc-light {
    fill: $disc-I-very-light;
  }
  &.fill-disc-light.Is,
  &.Is &.Parented.fill-disc-light {
    fill: $disc-Is-very-light;
  }
  &.fill-disc-light.IS,
  &.IS &.Parented.fill-disc-light {
    fill: $disc-IS-very-light;
  }
  &.fill-disc-light.Si,
  &.Si &.Parented.fill-disc-light {
    fill: $disc-Si-very-light;
  }
  &.fill-disc-light.S,
  &.S &.Parented.fill-disc-light {
    fill: $disc-S-very-light;
  }
  &.fill-disc-light.Sc,
  &.Sc &.Parented.fill-disc-light {
    fill: $disc-Sc-very-light;
  }
  &.fill-disc-light.SC,
  &.SC &.Parented.fill-disc-light {
    fill: $disc-SC-very-light;
  }
  &.fill-disc-light.Cs,
  &.Cs &.Parented.fill-disc-light {
    fill: $disc-Cs-very-light;
  }
  &.fill-disc-light.C,
  &.C &.Parented.fill-disc-light {
    fill: $disc-C-very-light;
  }
  &.fill-disc-light.Cd,
  &.Cd &.Parented.fill-disc-light {
    fill: $disc-Cd-very-light;
  }
  &.fill-disc-light.CD,
  &.CD &.Parented.fill-disc-light {
    fill: $disc-CD-very-light;
  }
  &.fill-disc-light.Dc,
  &.Dc &.Parented.fill-disc-light {
    fill: $disc-Dc-very-light;
  }
  &.fill-disc-light.D,
  &.D &.Parented.fill-disc-light {
    fill: $disc-D-very-light;
  }

  &.stroke-disc.empty,
  &.empty &.Parented.stroke-disc {
    stroke: white;
  }
  &.stroke-disc.Di,
  &.Di &.Parented.stroke-disc {
    stroke: $disc-Di;
  }
  &.stroke-disc.DI,
  &.DI &.Parented.stroke-disc {
    stroke: $disc-DI;
  }
  &.stroke-disc.Id,
  &.Id &.Parented.stroke-disc {
    stroke: $disc-Id;
  }
  &.stroke-disc.I,
  &.I &.Parented.stroke-disc {
    stroke: $disc-I;
  }
  &.stroke-disc.Is,
  &.Is &.Parented.stroke-disc {
    stroke: $disc-Is;
  }
  &.stroke-disc.IS,
  &.IS &.Parented.stroke-disc {
    stroke: $disc-IS;
  }
  &.stroke-disc.Si,
  &.Si &.Parented.stroke-disc {
    stroke: $disc-Si;
  }
  &.stroke-disc.S,
  &.S &.Parented.stroke-disc {
    stroke: $disc-S;
  }
  &.stroke-disc.Sc,
  &.Sc &.Parented.stroke-disc {
    stroke: $disc-Sc;
  }
  &.stroke-disc.SC,
  &.SC &.Parented.stroke-disc {
    stroke: $disc-SC;
  }
  &.stroke-disc.Cs,
  &.Cs &.Parented.stroke-disc {
    stroke: $disc-Cs;
  }
  &.stroke-disc.C,
  &.C &.Parented.stroke-disc {
    stroke: $disc-C;
  }
  &.stroke-disc.Cd,
  &.Cd &.Parented.stroke-disc {
    stroke: $disc-Cd;
  }
  &.stroke-disc.CD,
  &.CD &.Parented.stroke-disc {
    stroke: $disc-CD;
  }
  &.stroke-disc.Dc,
  &.Dc &.Parented.stroke-disc {
    stroke: $disc-Dc;
  }
  &.stroke-disc.D,
  &.D &.Parented.stroke-disc {
    stroke: $disc-D;
  }

  &.disc-color.empty,
  &.empty &.Parented.disc-color {
    color: $blue;
  }
  &.disc-color.Di,
  &.Di &.Parented.disc-color {
    color: $disc-Di;
  }
  &.disc-color.DI,
  &.DI &.Parented.disc-color {
    color: $disc-DI;
  }
  &.disc-color.Id,
  &.Id &.Parented.disc-color {
    color: $disc-Id;
  }
  &.disc-color.I,
  &.I &.Parented.disc-color {
    color: $disc-I;
  }
  &.disc-color.Is,
  &.Is &.Parented.disc-color {
    color: $disc-Is;
  }
  &.disc-color.IS,
  &.IS &.Parented.disc-color {
    color: $disc-IS;
  }
  &.disc-color.Si,
  &.Si &.Parented.disc-color {
    color: $disc-Si;
  }
  &.disc-color.S,
  &.S &.Parented.disc-color {
    color: $disc-S;
  }
  &.disc-color.Sc,
  &.Sc &.Parented.disc-color {
    color: $disc-Sc;
  }
  &.disc-color.SC,
  &.SC &.Parented.disc-color {
    color: $disc-SC;
  }
  &.disc-color.Cs,
  &.Cs &.Parented.disc-color {
    color: $disc-Cs;
  }
  &.disc-color.C,
  &.C &.Parented.disc-color {
    color: $disc-C;
  }
  &.disc-color.Cd,
  &.Cd &.Parented.disc-color {
    color: $disc-Cd;
  }
  &.disc-color.CD,
  &.CD &.Parented.disc-color {
    color: $disc-CD;
  }
  &.disc-color.Dc,
  &.Dc &.Parented.disc-color {
    color: $disc-Dc;
  }
  &.disc-color.D,
  &.D &.Parented.disc-color {
    color: $disc-D;
  }

  &.disc-border.empty,
  &.empty &.Parented.disc-border {
    border-color: $blue;
  }
  &.disc-border.Di,
  &.Di &.Parented.disc-border {
    border-color: $disc-Di;
  }
  &.disc-border.DI,
  &.DI &.Parented.disc-border {
    border-color: $disc-DI;
  }
  &.disc-border.Id,
  &.Id &.Parented.disc-border {
    border-color: $disc-Id;
  }
  &.disc-border.I,
  &.I &.Parented.disc-border {
    border-color: $disc-I;
  }
  &.disc-border.Is,
  &.Is &.Parented.disc-border {
    border-color: $disc-Is;
  }
  &.disc-border.IS,
  &.IS &.Parented.disc-border {
    border-color: $disc-IS;
  }
  &.disc-border.Si,
  &.Si &.Parented.disc-border {
    border-color: $disc-Si;
  }
  &.disc-border.S,
  &.S &.Parented.disc-border {
    border-color: $disc-S;
  }
  &.disc-border.Sc,
  &.Sc &.Parented.disc-border {
    border-color: $disc-Sc;
  }
  &.disc-border.SC,
  &.SC &.Parented.disc-border {
    border-color: $disc-SC;
  }
  &.disc-border.Cs,
  &.Cs &.Parented.disc-border {
    border-color: $disc-Cs;
  }
  &.disc-border.C,
  &.C &.Parented.disc-border {
    border-color: $disc-C;
  }
  &.disc-border.Cd,
  &.Cd &.Parented.disc-border {
    border-color: $disc-Cd;
  }
  &.disc-border.CD,
  &.CD &.Parented.disc-border {
    border-color: $disc-CD;
  }
  &.disc-border.Dc,
  &.Dc &.Parented.disc-border {
    border-color: $disc-Dc;
  }
  &.disc-border.D,
  &.D &.Parented.disc-border {
    border-color: $disc-D;
  }

  &.disc-background-light.empty,
  &.empty &.Parented.disc-background-light {
    background-color: white;
  }
  &.disc-background-light.Di,
  &.Di &.Parented.disc-background-light {
    background-color: $disc-Di-very-light;
  }
  &.disc-background-light.DI,
  &.DI &.Parented.disc-background-light {
    background-color: $disc-DI-very-light;
  }
  &.disc-background-light.Id,
  &.Id &.Parented.disc-background-light {
    background-color: $disc-Id-very-light;
  }
  &.disc-background-light.I,
  &.I &.Parented.disc-background-light {
    background-color: $disc-I-very-light;
  }
  &.disc-background-light.Is,
  &.Is &.Parented.disc-background-light {
    background-color: $disc-Is-very-light;
  }
  &.disc-background-light.IS,
  &.IS &.Parented.disc-background-light {
    background-color: $disc-IS-very-light;
  }
  &.disc-background-light.Si,
  &.Si &.Parented.disc-background-light {
    background-color: $disc-Si-very-light;
  }
  &.disc-background-light.S,
  &.S &.Parented.disc-background-light {
    background-color: $disc-S-very-light;
  }
  &.disc-background-light.Sc,
  &.Sc &.Parented.disc-background-light {
    background-color: $disc-Sc-very-light;
  }
  &.disc-background-light.SC,
  &.SC &.Parented.disc-background-light {
    background-color: $disc-SC-very-light;
  }
  &.disc-background-light.Cs,
  &.Cs &.Parented.disc-background-light {
    background-color: $disc-Cs-very-light;
  }
  &.disc-background-light.C,
  &.C &.Parented.disc-background-light {
    background-color: $disc-C-very-light;
  }
  &.disc-background-light.Cd,
  &.Cd &.Parented.disc-background-light {
    background-color: $disc-Cd-very-light;
  }
  &.disc-background-light.CD,
  &.CD &.Parented.disc-background-light {
    background-color: $disc-CD-very-light;
  }
  &.disc-background-light.Dc,
  &.Dc &.Parented.disc-background-light {
    background-color: $disc-Dc-very-light;
  }
  &.disc-background-light.D,
  &.D &.Parented.disc-background-light {
    background-color: $disc-D-very-light;
  }

  &.disc-background.Di,
  &.Di &.Parented.disc-background {
    background-color: $disc-Di;
  }
  &.disc-background.DI,
  &.DI &.Parented.disc-background {
    background-color: $disc-DI;
  }
  &.disc-background.Id,
  &.Id &.Parented.disc-background {
    background-color: $disc-Id;
  }
  &.disc-background.I,
  &.I &.Parented.disc-background {
    background-color: $disc-I;
  }
  &.disc-background.Is,
  &.Is &.Parented.disc-background {
    background-color: $disc-Is;
  }
  &.disc-background.IS,
  &.IS &.Parented.disc-background {
    background-color: $disc-IS;
  }
  &.disc-background.Si,
  &.Si &.Parented.disc-background {
    background-color: $disc-Si;
  }
  &.disc-background.S,
  &.S &.Parented.disc-background {
    background-color: $disc-S;
  }
  &.disc-background.Sc,
  &.Sc &.Parented.disc-background {
    background-color: $disc-Sc;
  }
  &.disc-background.SC,
  &.SC &.Parented.disc-background {
    background-color: $disc-SC;
  }
  &.disc-background.Cs,
  &.Cs &.Parented.disc-background {
    background-color: $disc-Cs;
  }
  &.disc-background.C,
  &.C &.Parented.disc-background {
    background-color: $disc-C;
  }
  &.disc-background.Cd,
  &.Cd &.Parented.disc-background {
    background-color: $disc-Cd;
  }
  &.disc-background.CD,
  &.CD &.Parented.disc-background {
    background-color: $disc-CD;
  }
  &.disc-background.Dc,
  &.Dc &.Parented.disc-background {
    background-color: $disc-Dc;
  }
  &.disc-background.D,
  &.D &.Parented.disc-background {
    background-color: $disc-D;
  }

  &.button-link {
    &.action-button {
      display: inline-block;
      background-color: rgb(44, 167, 228);
      color: white;
      padding: 8px 16px;
      text-wrap: nowrap;
      border-radius: 100px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
      clear: both;

      &:hover {
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
        background-color: rgb(28, 180, 246);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &:is(button),
  &:is(a) {
    &.action-button {
      display: inline-block;
      background-color: #1e749f;
      color: white;
      padding: 8px 16px;
      text-wrap: nowrap;
      border-radius: 100px;
      border: 1px solid #1e749f;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
      clear: both;
      transition: all 0.2s;
      &:disabled {
        background-color: rgb(168, 168, 168);
        color: $white;
        border: rgb(168, 168, 168);
        &:hover {
          border: #1e749f;
        }
      }

      &.no-transform {
        text-transform: none;
      }

      &.secondary {
        background-color: #fff;
        color: #1e749f;
        &.active {
          background: $light-crystal-blue;
          border: 0.5px solid $crystal-blue;
        }
        &:disabled {
          background-color: #fff;
          color: #1e749f;
        }
        &:hover {
          color: white;
        }
      }

      &.tertiary {
        background-color: #f09025;
        color: #fff;
        border: 1px solid #f09025;
      }

      &:hover {
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
        background-color: #01293d;
        border: 1px solid #01293d;
        cursor: pointer;
        text-decoration: none;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
          background-color: #1e749f;
          cursor: not-allowed;
        }
      }

      &.only-text {
        border: none;
        background-color: transparent;
        color: #1e749f;
        margin-bottom: 0;
        letter-spacing: 0;
        padding: 0;
        display: flex;
        gap: 4px;
        &:hover {
          box-shadow: none;
          background-color: transparent;
          cursor: pointer;
        }
        &.disabled {
          background-color: transparent;
          color: #cecece;
          cursor: not-allowed;
        }
      }

      &.sustainable {
        border-radius: 3px;
        border: 0.5px solid $gray-background;
        background: $bg-gray-2;
        color: $border-dark;
        font-weight: 400;
        letter-spacing: normal;
        text-transform: none;
        line-height: 1.4rem;
        padding: 4px 24px;

        &:hover {
          background: $bg-gray-2;
          border: 0.5px solid $gray-background;
          text-decoration: none;
        }
        &.active {
          background: $light-crystal-blue;
          border: 0.5px solid $crystal-blue;
        }
      }
    }

    &.destructive-button {
      display: inline-block;
      background-color: #db3c48;
      color: white;
      border: 1px solid #db3c48;
      padding: 8px 16px;
      text-wrap: nowrap;
      border-radius: 100px;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
      clear: both;
      transition: all 0.2s;

      &:hover {
        background-color: #85232c;
        cursor: pointer;
        border: 1px solid #85232c;

        &:disabled {
          background-color: #85232c;
          box-shadow: none;
          cursor: not-allowed;
        }
      }
    }

    &.cancel-button {
      display: inline-block;
      background-color: #9e9e9e;
      color: white;
      padding: 8px 16px;
      text-wrap: nowrap;
      border-radius: 100px;
      border: 1px solid #cecece;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
      clear: both;
      transition: all 0.2s;

      &:hover {
        background-color: #7e7e7e;
        border: 1px solid #7e7e7e;
        cursor: pointer;
        text-decoration: none;
      }

      &:disabled {
        background-color: #cecece;
        color: white;
        cursor: not-allowed;
      }
    }

    &.small {
      letter-spacing: 2px;
      font-size: 0.75rem;
      line-height: 1em;
    }
  }

  &.select-menu {
    width: 100%;
    max-width: 538px;
    height: 40px;
    border: 1px solid #cecece;
    border-radius: 3px;
  }

  &.capitalize {
    text-transform: capitalize;
  }
  &.uppercase {
    text-transform: uppercase;
  }

  &.tooltip {
    position: relative;
    cursor: pointer;

    .tooltip-content {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 10px);
      z-index: 100000;
      background-color: #ffffff;
      color: #3e3e3e;
      width: 100vw;
      max-width: 20rem;
      text-align: center;
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
      box-sizing: border-box;
      font-size: 1rem;
      line-height: 1.5em;
      opacity: 0;
      transition: all 0.2s;
      border-radius: 4px;
      padding: 6px 14px;
      pointer-events: none;
      translate: -50% 50%;
      scale: 0 0;
      font-weight: normal;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-width: 10px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent;
        left: calc(50% - 10px);
        top: 100%;
      }
    }

    &.bottom-tooltip {
      .tooltip-content {
        translate: -50% 0;
        bottom: initial;
        top: calc(100% + 10px);
      }

      .tooltip-content:after {
        border-color: transparent transparent #ffffff transparent;
        top: -20px;
      }
    }

    &.bottom-left-tooltip {
      .tooltip-content {
        translate: -95% 0;
        bottom: initial;
        top: calc(100% + 10px);
      }

      .tooltip-content:after {
        border-color: transparent transparent #ffffff transparent;
        top: -20px;
        left: calc(95% - 10px);
      }
    }

    &.right-tooltip {
      .tooltip-content {
        left: calc(100% + 10px);
        bottom: initial;
        top: 50%;
        translate: 0 -50%;
      }
      .tooltip-content:after {
        border-color: transparent #ffffff transparent transparent;
        left: -20px;
        top: calc(50% - 10px);
      }
    }

    &:hover {
      .tooltip-content {
        opacity: 1;
        scale: 1 1;
        translate: -50% 0;
      }

      &.bottom-tooltip {
        .tooltip-content {
          translate: -50% 0;
          border-color: transparent transparent transparent transparent;
        }
      }

      &.bottom-left-tooltip {
        .tooltip-content {
          translate: -95% 0;
          border-color: transparent transparent #ffffff transparent;
        }
      }

      &.right-tooltip {
        .tooltip-content {
          translate: 0 -50%;
          border-color: transparent transparent transparent transparent;
        }
      }
    }
  }
  &.subtext {
    font-size: 0.875rem;
    color: $crystal-midnight-blue;
    line-height: 1.125rem;
  }
  &.title {
    font-size: 1.125rem;
    line-height: 140%;
    font-weight: 400;
    color: $crystal-midnight-blue;

    &.semi-bold {
      font-weight: 600;
    }

    &.bold {
      font-weight: 700;
    }

    &.italic {
      font-style: italic;
    }

    &.light {
      font-weight: 300;
    }

    &.extra-small {
      font-size: 0.7rem;
    }

    &.small {
      font-size: 0.875rem;
    }

    &.normal {
      font-size: 1rem;
    }

    &.medium {
      font-size: 1.125rem;
    }

    &.large {
      font-size: 1.5rem;
    }

    &.extra-large {
      font-size: 2rem;
    }

    &.gray {
      color: $gray-info;
    }

    &.light-gray {
      color: $light-gray-text;
    }

    &.dark-gray {
      color: $gray-text;
    }

    &.blue {
      color: $blue;
    }
    &.action-blue {
      color: $action-blue;
    }
    &.white {
      color: $white;
    }
    &.red {
      color: $red;
    }
    &.underline {
      text-decoration: underline;
    }
  }
  &.box-shadow {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
}

.CE {
  @include crystal-eyes-styles;
}

.crystalEyesWrapper {
  :global .CE {
    @include crystal-eyes-styles;
  }
}
